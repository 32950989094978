
import { defineComponent } from 'vue';
import Group from '@/components/Group.vue';

export default defineComponent({
    name: 'PurpleDragonfly',
    components: {
        Group
    }
});
