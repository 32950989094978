
import { defineComponent } from 'vue';
import TreasuresGift from '@/components/TreasuresGift.vue';

export default defineComponent({
    name: 'TreasuresGiftView',
    components: {
        TreasuresGift
    }
});
