import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pb-2 text-2xl"
}
const _hoisted_2 = {
  key: 1,
  class: "pb-2 text-lg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_box = _resolveComponent("box")

  return (_openBlock(), _createBlock(_component_box, { class: _ctx.computedClass }, {
    default: _withCtx(() => [
      (_ctx.label)
        ? (_openBlock(), _createBlock("div", _hoisted_1, _toDisplayString(_ctx.label), 1))
        : _createCommentVNode("", true),
      (_ctx.sublabel)
        ? (_openBlock(), _createBlock("div", _hoisted_2, _toDisplayString(_ctx.sublabel), 1))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class"]))
}