
import { defineComponent } from 'vue';
import QuoteBox from './QuoteBox.vue';
import Box from './Box.vue';
import BuyButton from './BuyButton.vue';

export default defineComponent({
    name: 'TreasuresGiftBody',
    components: {
        QuoteBox,
        Box,
        BuyButton
    }
});
